import React, { useState } from "react";
import { DatePicker, Input, List } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import { container } from "tsyringe";
import { CounterStore } from "@/entities/Dashboard/widgets/DashboardPlatform/stores/CounterStore";

import { quickOptions } from "./constants";

const counterStore = container.resolve(CounterStore);


const { Search } = Input;
const filterSort = (optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
export const PredefinedPeriods = ({
  closePopup,
}) => {
  const [search, setSearch] = useState('');
  const { relativePeriod } = counterStore;
  const [periodFrom, periodTo] = relativePeriod || [];
  const handleSelect = ({ target }) => {
    const { from, to, display } = target?.dataset || {};
    if (!display) return;
    counterStore.relativePeriod = [from, to];
    counterStore.relativeDisplay = display;
    counterStore.mode = 'live';
    closePopup();
  };

  const handleSearch = ({ target: { value } }) => {
    setSearch(value);
  }

  const filteredOptions = quickOptions.filter(({ display }) => display.toLowerCase().indexOf(search.toLowerCase()) > -1);

  return (
    <div className="periods">
      <Input
        addonBefore={<SearchOutlined />}
        placeholder="быстрый поиск"
        onChange={handleSearch}
      />
      <List
        className="period-list"
        size="small"
        split={false}
        dataSource={filteredOptions}
        itemLayout="vertical"
        renderItem={({ from, to, display}) => (
          <List.Item
            data-from={from}
            data-to={to}
            data-display={display}
            className={periodFrom === from && periodTo === to ? 'active' : ''}
          >
            {display}
          </List.Item>
        )}
        onClick={handleSelect}
      />
    </div>
  );
}
