import { FC, useCallback, useEffect, useState } from 'react';

import { FieldInput } from '@/shared/ui/Fields/components/FieldInput/FieldInput';
import { FieldSwitch } from '@/shared/ui/Fields/components/FieldSwitch/FieldSwitch';
import { useFieldArray, useForm } from 'react-hook-form';
import Box from '@mui/system/Box';
import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import Stack from '@mui/system/Stack';
import { PlusIcon } from '@/shared/ui/Icons/PlusIcon/PlusIcon';
import { useParams } from 'react-router-dom';
import { useResolve } from '@/hooks/useResolve';
import { PropertyService } from '@/entities/Properties/api/PropertyService';
import { RegistrableValues } from '@/shared/lib/types';
import { Properties } from '@/entities/Properties/model/types';
import { observer } from 'mobx-react-lite';
import { TrashIcon } from '@/shared/ui/Icons/TrashIcon/TrashIcon';
import { FieldSelect } from '@/shared/ui/Fields/components/FieldSelect/FieldSelect';

export interface PropertyListFormProps {}

export const PropertyListForm: FC<PropertyListFormProps> = observer(() => {
  const { propertyId } = useParams();
  const [isDisabled, setIsDisabled] = useState(false)

  const propertyService = useResolve(PropertyService, {
    [RegistrableValues.PropertyId]: propertyId,
  });

  const { control, register, handleSubmit, watch } = useForm({
    values: propertyService.property,
  });

  const { fields, append, remove } = useFieldArray({
    name: 'propertyList',
    control,
  });

  const onSubmit = useCallback(
    (data: Properties) => {
      if (propertyId) {
        void propertyService.updateProperty(data);
      }
    },
    [propertyId, propertyService]
  );

  const handleOption = (e: string) => {
    if (e === 'Empty Object') {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }

  useEffect(() => {
    if (fields.length === 0) {
      append({ type: 'String', name: '', value: '', isPrivate: false });
    }
  }, [append, fields.length]);

  return (
    <Box bgcolor='var(--paper-color)' borderRadius={6} padding={4}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack className='overflow-y-auto overflow-x-hidden' gap={2.5} height='calc(100vh - 320px)'>
          {fields.map((field, index) => {
            const isPrivate = watch(`propertyList.${index}.isPrivate`);
            return (
              <Stack
                key={field.id}
                direction='row'
                justifyContent='center'
                marginLeft='2px'
                gap={2.5}
              >
                <Box flex={1}>
                  <FieldInput
                    label='Название переменной'
                    register={register}
                    error=''
                    name={`propertyList.${index}.name`}
                  />
                </Box>
                <Box flex={1}>
                  <FieldSelect
                    label='Тип переменной'
                    control={control}
                    error=''
                    options={['String', 'Boolean', 'Integer', 'Double', 'Empty Object']}
                    name={`propertyList.${index}.type`}
                    onChange={handleOption}
                  />
                </Box>
                <Box flex={1}>
                  <FieldInput
                    label='Значение переменной'
                    register={register}
                    error=''
                    name={`propertyList.${index}.value`}
                    disabled={isDisabled}
                  />
                </Box>
                <Box alignSelf='center' height={0}>
                  <FieldSwitch
                    label={isPrivate ? 'Приватная' : 'Публичная'}
                    control={control}
                    error=''
                    name={`propertyList.${index}.isPrivate`}
                    invertChecked
                  />
                </Box>
                <Box alignSelf='center' height={10}>
                  <Button
                    circle
                    size={ButtonSizes.Small}
                    variant={ButtonVariants.Outlined}
                    onClick={() => {
                      remove(index);
                    }}
                  >
                    <TrashIcon />
                  </Button>
                </Box>
              </Stack>
            );
          })}
        </Stack>

        <Stack direction='row' justifyContent='space-between' gap={2.5} marginTop={2.5}>
          <Box width='300px'>
            <Button
              doesUseInFormOrTable
              variant={ButtonVariants.Secondary}
              onClick={() => {
                append({ type: 'String', name: '', value: '', isPrivate: false });
              }}
            >
              <Stack direction='row' gap={1} alignItems='center'>
                <PlusIcon />
                <p>Добавить</p>
              </Stack>
            </Button>
          </Box>
          <Box width='300px'>
            <Button
              loading={propertyService.isLoadingEditProperty}
              type='submit'
              variant={ButtonVariants.Primary}
            >
              Сохранить
            </Button>
          </Box>
        </Stack>
      </form>
    </Box>
  );
});

PropertyListForm.displayName = 'PropertyListForm';
