import { Button, DatePicker } from "antd";
import { container } from "tsyringe";

import { CounterStore } from "@/entities/Dashboard/widgets/DashboardPlatform/stores/CounterStore";
import { CounterService } from "@/entities/Dashboard/widgets/DashboardPlatform/services/CounterService";

const counterStore = container.resolve(CounterStore);
const counterService = container.resolve(CounterService);

const showTime = { format: 'HH:mm', showSecond: false };

export const PeriodPicker = () => {

  const { absolutePeriod, relativePeriod, mode } = counterStore;
  const [from, to] = absolutePeriod;
  const [relFrom = '', relTo = ''] = relativePeriod || [];

  const formatFrom = mode === 'live'
    ? (val) => relFrom
    : 'DD.MM.YYYY HH:mm';

  const formatTo = mode === 'live'
    ? (val) => relTo
    : 'DD.MM.YYYY HH:mm';

  const updateFrom = (from) => {
    const [, to] = counterStore.absolutePeriod;
    counterStore.absolutePeriod = [from, to];
  }

  const updateTo = (to) => {
    const [from] = counterStore.absolutePeriod;
    counterStore.absolutePeriod = [from, to];
  }

  const handleChange = (update) => (day) => {
    if (counterStore.mode === 'history') {
      update(day);
      return;
    }
    counterService.syncAbsolutePeriod();
    update(day);
    counterStore.mode = 'history';
  }
  return (
    <div className="picker">
      <DatePicker
        picker='date'
        allowClear={false}
        showTime={showTime}
        value={from}
        format={formatFrom}
        onChange={handleChange(updateFrom)}
        // onCalendarChange={(...args) => {
        //   console.log('onCalendarChange: ', ...args);
        // }}
        // onPickerValueChange={(...args) => {
        //   console.log('onPickerValueChange: ', ...args);
        // }}
        // value={from}
        // placement='left'
        // dropdownAlign={{
        //   points: ['cr', 'cl'],
        //   offset: [0, 4],
        //   overflow: { adjustX: 1, adjustY: 1 },
        // }}
        // popupStyle={{ border: 'solid 1px red' }}
      />
      <DatePicker
        picker='date'
        allowClear={false}
        showTime={showTime}
        value={to}
        format={formatTo}
        onChange={handleChange(updateTo)}
      />
    </div>
  );
};
