import React, { memo, useMemo } from 'react';

import Tooltip from '@mui/material/Tooltip';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { IMonitoringItem } from '@/hooks/tracing/types';
// @ts-ignore
import { ReactComponent as CheckIcon } from '@/assets/icons/check.svg';
// @ts-ignore
import { ReactComponent as WarningIcon } from '@/assets/icons/warning.svg';
import { truncateData } from '@/entities/Tracing/utils/TruncateData';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { DATE_TIME_FORMAT_STRING } from '@/shared/lib/constants';

import { ITableDescriptor } from '../table/types';
import { TracingTableFields } from './tracingTableFields';

import styles from './styles.module.scss';

export const tracingTableDescriptor: ITableDescriptor<IMonitoringItem> = {
  columns: [
    TracingTableFields.Flow,
    TracingTableFields.Block,
    TracingTableFields.IncomingMessage,
    TracingTableFields.MessageStatus,
    TracingTableFields.DateOfExecution,
  ],

  buildRowLink(row) {
    return `${row.messageId}/monitoring`;
  },

  renderers: {
    [TracingTableFields.Flow]: {
      width: 10,
      Title() {
        return <span>Поток</span>;
      },

      Value: memo(({ data: { flowName } }) => {
        return (
          <Typography noWrap className={styles.flowCell}>
            {truncateData(flowName)}
          </Typography>
        );
      }),
    },

    [TracingTableFields.Block]: {
      width: 10,
      Title() {
        return <span>Блок</span>;
      },

      Value: memo(({ data: { blockName } }) => {
        return <Typography noWrap>{truncateData(blockName) || `Блок`}</Typography>;
      }),
    },

    [TracingTableFields.IncomingMessage]: {
      width: 55,
      Title() {
        return <span>Входящее сообщение</span>;
      },

      Value: memo(({ data: { inputTextPreview } }) => {
        return <Typography noWrap>{truncateData(inputTextPreview)}</Typography>;
      }),
    },

    [TracingTableFields.MessageStatus]: {
      width: 10,
      Title() {
        return <span>Статус сообщения</span>;
      },

      Value: memo(({ data: { isFailed } }) => {
        return isFailed ? (
          <Tooltip placement='top' arrow title='Блок выполнен с ошибкой'>
            <WarningIcon />
          </Tooltip>
        ) : (
          <CheckIcon />
        );
      }),
    },

    [TracingTableFields.DateOfExecution]: {
      width: 15,
      Title() {
        return <span>Дата выполнения</span>;
      },

      Value: memo(({ data: { endDate } }) => {
        const date = useMemo(() => parseISO(endDate), [endDate]);
        const formattedDate = useMemo(() => format(date, DATE_TIME_FORMAT_STRING), [date]);

        return (
          <Typography noWrap className={styles.dateCell}>
            {formattedDate}
          </Typography>
        );
      }),
    },
  },
};
