export var MonthCode;
(function (MonthCode) {
    MonthCode["JAN"] = "JAN";
    MonthCode["FEB"] = "FEB";
    MonthCode["MAR"] = "MAR";
    MonthCode["APR"] = "APR";
    MonthCode["MAY"] = "MAY";
    MonthCode["JUN"] = "JUN";
    MonthCode["JUL"] = "JUL";
    MonthCode["AUG"] = "AUG";
    MonthCode["SEP"] = "SEP";
    MonthCode["OCT"] = "OCT";
    MonthCode["NOV"] = "NOV";
    MonthCode["DEC"] = "DEC";
})(MonthCode || (MonthCode = {}));
