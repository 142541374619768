import {makeAutoObservable, runInAction} from 'mobx';
import {normalize} from '@/shared/lib/normalize';
import {singleton} from 'tsyringe';

import {ApiService} from '../../../shared/api/Api/services/ApiService';
import {UserStore} from '../model/UserStore';
import {User} from '../model/types';

@singleton()
export class UserService {
  constructor(private apiService: ApiService, private userStore: UserStore) {
    makeAutoObservable(this);
  }

  async getUsers() {
    try {
      const response = await this.apiService.instance.get<User[]>('/editor/user/all')

      runInAction(() => {
        this.userStore.users = normalize(response.data, 'id');
        this.userStore.isNotRight = false
      });

      return response.data;
    } catch (error) {
      this.userStore.isNotRight = true
    }
  }
}
