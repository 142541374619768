import { ChangeEvent, forwardRef, memo, useCallback, useEffect, useState } from 'react';

import { Input, InputProps } from '@/shared/ui/InputNumber/Input';
import cn from 'classnames';
import { PlusIcon } from '@/shared/ui/Icons/PlusIcon/PlusIcon';
import { MinusIcon } from '@/shared/ui/Icons/MinusIcon/MinusIcon';
import {
  decrementCheck,
  incrementCheck,
} from '@/shared/ui/Fields/components/FieldInputNumber/Utils/utils';

import styles from './InputNumber.module.scss';

export interface InputNumberProps extends Omit<InputProps, 'onChange'> {
  step?: number;
  min?: number;
  max?: number;
  type?: string;
  value?: string;
  onChange?: (value: string) => void;
}

export const InputNumber = memo(
  forwardRef<HTMLInputElement, InputNumberProps>(
    (
      {
        type,
        step = type === 'DoubleField' ? 0.1 : 1,
        min = 0,
        max = 99999999999,
        defaultValue = min,
        onChange,
        value = defaultValue,
        ...props
      },
      ref
    ) => {
      const [valueInput, setValue] = useState<string>(String(value));
      const minNumber = Number(valueInput) < min;
      const maxNumber = Number(valueInput) > max;
      const setterValue = (value: string) => {
        setValue(value);
        onChange?.(value);
      };

      useEffect(() => {
        if (minNumber) {
          setterValue(String(min));
        }
        if (maxNumber) {
          setterValue(String(max));
        }
      }, [min, max, minNumber, maxNumber]);
      const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const numberCondition = Number(value) >= min && Number(value) <= max;
        if ((Number.parseInt(value) || !Number.isInteger(value)) && numberCondition) {
          setterValue(value);
        }
      };

      const handleMinusButtonClick = useCallback(() => {
        const numberValue = Number(value) - step;
        const decrementValue = decrementCheck(min, numberValue);
        setterValue(String(decrementValue));
      }, [valueInput]);

      const handlePlusButtonClick = useCallback(() => {
        const numberValue = Number(value) + step;
        const incrementValue = incrementCheck(max, numberValue);
        setterValue(String(incrementValue));
      }, [value]);

      return (
        <Input
          {...props}
          ref={ref}
          defaultValue={defaultValue}
          className={styles.root}
          value={valueInput}
          onChange={handleChange}
          startAdornment={
            <button
              type='button'
              className={cn(styles.button, styles.start)}
              onClick={handleMinusButtonClick}
            >
              <MinusIcon width={10} height={10} />
            </button>
          }
          endAdornment={
            <button
              type='button'
              className={cn(styles.button, styles.end)}
              onClick={handlePlusButtonClick}
            >
              <PlusIcon width={10} height={10} />
            </button>
          }
        />
      );
    }
  )
);
