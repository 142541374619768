import { inject, injectable } from 'tsyringe';
import { Normalized } from '@/shared/lib/normalize';
import { makeAutoObservable, runInAction } from 'mobx';
import { Flow } from '@/entities/Flow/types';
import { AxiosResponse } from 'axios';
import { RegistrableValues } from '@/shared/lib/types';

import { eventEmitter } from '../../../shared/api/EventEmitter/EventEmitter';
import { CustomEvents } from '../../../shared/api/EventEmitter/types';
import { ApiService } from '../../../shared/api/Api/services/ApiService';
import { AddBlockRelationRequest, AddBlockRelationResponse, Connect, Processor } from '../types';
import { ConnectStore } from '../stores/ConnectStore';

@injectable()
export class ConnectService {
  constructor(
    @inject(RegistrableValues.BlockId) private blockId: string,
    private apiService: ApiService,
    private connectStore: ConnectStore
  ) {
    makeAutoObservable(this);
  }

  get inputList(): Normalized<Connect> | null {
    return this.connectStore.inputList?.[this.blockId] || null;
  }

  get processorList(): Normalized<Processor> | null {
    return this.connectStore.processorList?.[this.blockId] || null;
  }

  get outputList(): Normalized<Connect> | null {
    return this.connectStore.outputList?.[this.blockId] || null;
  }

  emitChangeConnects(blockId: string = this.blockId) {
    eventEmitter.emit(CustomEvents.ChangeConnects, blockId, {
      inputList: this.connectStore.inputList,
      processorList: this.connectStore.processorList,
      outputList: this.connectStore.outputList,
    });
  }

  createConnect(type: string, connect: Connect): void {
    switch (type) {
      case 'input': {
        if (this.connectStore.inputList) {
          this.connectStore.inputList[this.blockId].ids.push(connect.id);
          this.connectStore.inputList[this.blockId].entities[connect.id] = connect;
        }
        break;
      }
      case 'processor': {
        if (this.connectStore.processorList) {
          this.connectStore.processorList[this.blockId].ids.push(connect.id);
          this.connectStore.processorList[this.blockId].entities[connect.id] = connect;
        }
        break;
      }
      case 'output': {
        if (this.connectStore.outputList) {
          this.connectStore.outputList[this.blockId].ids.push(connect.id);
          this.connectStore.outputList[this.blockId].entities[connect.id] = connect;
        }
        break;
      }
    }

    this.emitChangeConnects();
  }

  async createBothConnects(
    sourceBlockId: string,
    targetBlockId: string,
    connect: Connect,
    flow: Flow
  ) {
    const response = await this.apiService.instance.post<
      AddBlockRelationRequest,
      AxiosResponse<AddBlockRelationResponse>
    >(`/editor/connect/type/${connect.typeConnect || connect.typeProcessor}/block/relation`, {
      connect,
      flow,
      inputBlockId: targetBlockId,
      outputBlockId: sourceBlockId,
    });

    const outputConnectId = response.data.outputConnect.id || '';
    const inputConnectId = response.data.inputConnect.id || '';

    runInAction(() => {
      if (this.connectStore.outputList && this.connectStore.inputList) {
        if (response.data.newOutputConnect) {
          this.connectStore.outputList[sourceBlockId].ids.push(outputConnectId);
          this.connectStore.outputList[sourceBlockId].entities[outputConnectId] =
            response.data.outputConnect;
        } else {
          this.connectStore.outputList[sourceBlockId].entities[outputConnectId] =
            response.data.outputConnect;
        }

        if (response.data.newInputConnect) {
          this.connectStore.inputList[targetBlockId].ids.push(inputConnectId);
          this.connectStore.inputList[targetBlockId].entities[inputConnectId] =
            response.data.inputConnect;
        } else {
          this.connectStore.inputList[targetBlockId].entities[inputConnectId] =
            response.data.inputConnect;
        }
      }
    });

    this.emitChangeConnects(sourceBlockId);
    this.emitChangeConnects(targetBlockId);
  }

  updateConnect(type: string, connect: Connect): void {
    switch (type) {
      case 'input': {
        if (this.connectStore.inputList) {
          this.connectStore.inputList[this.blockId].entities[connect.id] = connect;
        }
        break;
      }
      case 'processor': {
        if (this.connectStore.processorList) {
          this.connectStore.processorList[this.blockId].entities[connect.id] = connect;
        }
        break;
      }
      case 'output': {
        if (this.connectStore.outputList) {
          this.connectStore.outputList[this.blockId].entities[connect.id] = connect;
        }
        break;
      }
    }

    this.emitChangeConnects();
  }

  deleteConnect(connectId: string, type: string): void {
    switch (type) {
      case 'input': {
        if (this.inputList) {
          this.inputList.ids.splice(this.inputList.ids.indexOf(connectId), 1);

          delete this.inputList.entities[connectId];
        }
        break;
      }
      case 'processor': {
        if (this.processorList) {
          this.processorList.ids.splice(this.processorList.ids.indexOf(connectId), 1);

          delete this.processorList.entities[connectId];
        }
        break;
      }
      case 'output': {
        if (this.outputList) {
          this.outputList.ids.splice(this.outputList.ids.indexOf(connectId), 1);

          delete this.outputList.entities[connectId];
        }
        break;
      }
    }

    this.emitChangeConnects();
  }

  getFormValues(type: string | null, id: string | null): Connect | null {
    if (type && id) {
      switch (type) {
        case 'input': {
          return this.inputList?.entities[id] || null;
        }
        case 'processor': {
          return (this.processorList?.entities[id] || null) as Connect;
        }
        case 'output': {
          return this.outputList?.entities[id] || null;
        }
        default:
          return null;
      }
    }

    return null;
  }

  reorderProcessors(newOrder: string[]) {
    if (this.connectStore.processorList?.[this.blockId]) {
      this.connectStore.processorList[this.blockId].ids = newOrder;

      this.emitChangeConnects();
    }
  }
}
