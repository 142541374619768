import { singleton } from 'tsyringe';
import { makeAutoObservable } from 'mobx';
import { Normalized } from '@/shared/lib/normalize';

import { Flow, RequestFilter, RequestSort } from '../types';

@singleton()
export class FlowStore {
  flows: Normalized<Flow>;
  filter: RequestFilter[];
  sorting: RequestSort | null;
  totalPages: number;
  totalElements: number | null;
  isLoadingFlows: boolean;
  isLoadingGFlows: boolean;
  isLoadingFlow: boolean;
  isLoadingUpdateFlow: boolean;
  isLoadingDeleteFlow: boolean;
  selectedFlowId: string | null;
  diffFlow: any;

  constructor() {
    this.flows = { ids: [], entities: {} };
    this.filter = [];
    this.sorting = null;
    this.totalPages = 0;
    this.totalElements = null;
    this.isLoadingGFlows = false;
    this.isLoadingFlows = false;
    this.isLoadingFlow = false;
    this.isLoadingUpdateFlow = false;
    this.isLoadingDeleteFlow = false;
    this.selectedFlowId = null;
    this.diffFlow = null;

    makeAutoObservable(this);
  }

  get allFlowsAreReceived() {
    if (this.totalElements) {
      return this.totalElements === this.flows.ids.length;
    }
    return false;
  }

}
