import { FC, useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { UserService, UserStore } from '@/entities/User';
import { RoleService } from '@/entities/Role';
import { UpdateUserForm } from '@/features/UpdateUser';
import { DeleteUserButton, DeleteUserDialog } from '@/features/DeleteUser';
import { CreateUserButton, CreateUserFormDialog } from '@/features/CreateUser';
import { RequestAdminRightButton } from '@/features/RequestAdminRight';

const userService = container.resolve(UserService);
const roleService = container.resolve(RoleService);
const userStore = container.resolve(UserStore);

export interface UsersPageProps {}

export const UsersPage: FC<UsersPageProps> = observer(() => {
  useEffect(() => {
    roleService.getRoles().then(() => {
      userService.getUsers();
    });
  }, []);

  return (
    <>
      <Box padding={4}>
        <Paper elevation={0} sx={{ padding: 4 }}>
          <Stack gap={5} display='flex' flexDirection='column'>
            <Stack gap={5} height='calc(100vh - 381px)' sx={{ overflowY: 'auto', padding: 2 }}>
              {userStore.users?.ids.map((id) => (
                <UpdateUserForm
                  key={id}
                  userId={id}
                  deleteButton={<DeleteUserButton userId={id} />}
                />
              ))}
            </Stack>
            <Box width={300} alignSelf='end'>
              {userStore.isNotRight ? <RequestAdminRightButton /> : <CreateUserButton />}
            </Box>
          </Stack>
        </Paper>
      </Box>
      <DeleteUserDialog />
      <CreateUserFormDialog />
    </>
  );
});

UsersPage.displayName = 'UsersPage';
