import React from 'react';

import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Divider } from '@mui/material';
import { PythonBeanPage } from '@/pages/PythonBeanPage';
import { UpsertSettingsForm } from '@/features/UpsertSettings';
import { CompanySettings } from '@/pages/CompanySettings';
import { NotificationPage } from '@/pages/NotificationPage';
import Paper from '@mui/material/Paper';
import { UsersPage } from '@/pages/UsersPage';
import { RolesPage } from '@/pages/RolesPage';
import { ExternalAppsPage } from '@/pages/ExternalAppsPage';
import { SettingsUserAuthPage } from '@/pages/SettingsUserAuthPage';
import Box from '@mui/material/Box';
import MenuList from '@/shared/ui/MenuList';
import {KeyValueStorePage} from "@/pages/KeyValueStorePage";

const linkBuilder = (domain) => (path) => `${domain}settings/${path}`;

export const SettingsPage = () => {
  const serverPath = useSelector((state) => {
    return state.location.serverPath;
  });
  const getLink = linkBuilder(serverPath);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <Box>
        <MenuList
          sx={{
            height: '60px',
          }}
          elements={[
            {
              label: 'Основные',
              link: getLink('global'),
            },
            {
              label: 'Внешние сервисы',
              link: getLink('externalService'),
            },
            {
              label: 'KeyValue Store',
              link: getLink('keyValueStore'),
            },
            {
              label: 'Компания',
              link: getLink('company'),
            },
            {
              label: 'Уведомления',
              link: getLink('notifications'),
            },
            {
              label: 'Учетные системы',
              link: getLink('auth'),
            },
            {
              label: 'Пользователи',
              link: getLink('users'),
            },
            {
              label: 'Роли пользователей',
              link: getLink('roles'),
            },
            {
              label: 'Python скрипты',
              link: getLink('pythonBeans'),
            },
          ]}
          size='medium'
        />
      </Box>
      <Divider orientation='vertical' flexItem />
      <Routes>
        <Route path='' element={<Navigate to='global' replace />} />
        <Route
          path='global'
          element={
            <Paper elevation={0} sx={{ margin: '30px', padding: '32px', height: '100%' }}>
              <UpsertSettingsForm />
            </Paper>
          }
        />
        <Route
          path='externalService'
          element={
            <Paper elevation={0} sx={{ margin: '30px', padding: '32px', height: '100%' }}>
              <ExternalAppsPage />
            </Paper>
          }
        />
        <Route
            path='keyValueStore'
            element={
              <Paper elevation={0} sx={{ margin: '30px', padding: '32px', height: '100%' }}>
                <KeyValueStorePage />
              </Paper>
            }
        />
        <Route path='pythonBeans' element={<PythonBeanPage hideChooseScriptButton />} />
        <Route path='auth' element={<SettingsUserAuthPage />} />
        <Route path='users' element={<UsersPage />} />
        <Route
          path='roles'
          element={
            <Paper elevation={0} sx={{ margin: '30px', padding: '32px', height: '100%' }}>
              <RolesPage />
            </Paper>
          }
        />
        <Route
          path='company'
          element={
            <Paper elevation={0} sx={{ margin: '30px', padding: '32px', height: '100%' }}>
              <CompanySettings />
            </Paper>
          }
        />
        <Route path='notifications' element={<NotificationPage />} />
      </Routes>
    </Box>
  );
};
