import React from "react";
import {Tabs} from "@/entities/MessageNode/lib/types";
import {Box} from "@mui/material";

export interface TabPanelProps {
    children?: React.ReactNode;
    index: string;
    value: string;
    height?: string;
}

export const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {children}
        </Box>
    );
}