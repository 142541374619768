export var Segment;
(function (Segment) {
    Segment["seconds"] = "seconds";
    Segment["minutes"] = "minutes";
    Segment["hours"] = "hours";
    Segment["dayOfMonth"] = "dayOfMonth";
    Segment["month"] = "month";
    Segment["dayOfWeek"] = "dayOfWeek";
    Segment["year"] = "year";
})(Segment || (Segment = {}));
