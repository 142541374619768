import { makeAutoObservable } from 'mobx';
import { injectable } from 'tsyringe';
import { FlowVersionStore } from '@/entities/FlowVersion';

@injectable()
export class ExportVersionService {
  constructor(private flowVersionStore: FlowVersionStore) {
    makeAutoObservable(this);
  }

  exportVersion(versionId: string) {
    const version = this.flowVersionStore.versions?.entities[versionId];

    const text = JSON.stringify(version, null, 2);

    const blob = new Blob([text], { type: 'application/json' });

    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');

    a.href = url;
    a.download = `Версия ${version?.name || ''} потока ${version?.versionFlow?.name}`;
    a.click();
  }
}
