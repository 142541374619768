import { injectable } from 'tsyringe';
import { makeAutoObservable, runInAction } from 'mobx';
import { uniq } from 'lodash';
import { notify } from '@/shared/ui/Toast/notify';
import { AxiosResponse } from 'axios';
import dayjs, { Dayjs } from "dayjs";

import { normalize } from '@/shared/lib/normalize';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { CounterStore } from '../stores/CounterStore';
import {
  CounterTimeline,
} from "@/entities/Dashboard/widgets/DashboardPlatform/components/DashboardPanel/CounterPanel/types";

@injectable()
export class CounterService {
  constructor(private apiService: ApiService, private counterStore: CounterStore) {
    makeAutoObservable(this);
  }

  get counterTimelines(): CounterStore['counterTimelines'] {
    return this.counterStore.counterTimelines;
  }

  get mode() {
    return this.counterStore.mode;
  }

  parseRaw(raw: string) {
    if (raw === 'now') return dayjs();
    const parsed = raw.match(/now([+-])(\d+)(\w+)/);
    if (!parsed) return dayjs();
    const [, act, num, type] = parsed;
    const action = act === '-' ? 'subtract' : 'add';
    return dayjs()[action](Number.parseInt(num), type);

  }

  parseRelativePeriod(): [Dayjs, Dayjs] {
    const [rawStart, rawEnd] = this.counterStore.relativePeriod;
    const start = this.parseRaw(rawStart);
    const end = this.parseRaw(rawEnd);
    this.counterStore.absolutePeriod = [start, end];
    return [start, end];
  }

  get period() {
    if (this.counterStore.mode === 'live') {
      return this.parseRelativePeriod();
    }
    return this.counterStore.absolutePeriod;
  }

  get unixPeriod() {
    const [start, end] = this.period;
    return [start.unix(), end.unix()];
  }

  syncAbsolutePeriod() {
    this.counterStore.absolutePeriod = this.parseRelativePeriod();
  }

  unixToDayjs([start, end]: [number, number]): [Dayjs, Dayjs] {
    return [dayjs(start * 1000), dayjs(end * 1000)];
  }

  dayjsToUnix([start, end]: [Dayjs, Dayjs]): [number, number] {
    return [start.unix(), end.unix()];
  }

  async getCounterTimeLinesByBlockIds(blockIdList : string[]): Promise<void> {
    const [start, end] = this.dayjsToUnix(
      this.mode === 'live'
        ? this.parseRelativePeriod()
        : this.counterStore.absolutePeriod
    );

    try {
      const response = await this.apiService.instance.post<
        CounterTimeline[],
        AxiosResponse<CounterTimeline[]>,
        CounterTimeline[]
      >('editor/dashboard/counter/search', {
        blockIdList,
        start,
        end
      });

      const normalizedCounterTimelines = normalize(response.data, 'blockId');

      runInAction(() => {
        this.counterStore.counterTimelines.ids = uniq([...this.counterStore.counterTimelines.ids, ...normalizedCounterTimelines.ids]);
        this.counterStore.counterTimelines.entities = {
          ...this.counterStore.counterTimelines.entities,
          ...normalizedCounterTimelines.entities,
        };
      });
    } catch (error) {
      throw error;
    }
  }

}
