import * as React from 'react';
import { FC, memo } from 'react';

import { Controller } from 'react-hook-form';
import { SelectOption } from '@mui/base';
import MenuItem from '@mui/material/MenuItem';
import { Input } from '@/shared/ui';
import { FieldProps } from '@/shared/ui/Fields/Fields';
import { amendDraggable } from "@/shared/lib/extendDndkit";
import { isType } from '@/shared/lib/isType';

export interface FieldSelectProps extends Omit<FieldProps, 'options'> {
  options: SelectOption<string>[] | string[];
  multiple?: boolean;
  onChange?: (value: any) => void;
  draggable: boolean;
}

/**
 * Компонент CronSelect для генератора форм
 */
export const FieldSelect: FC<FieldSelectProps> = memo(
  ({ name, label, options, isRequired, description, error, defaultValue, control, onChange, draggable }) => {
    return (
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field }) => {
          return (
            <Input
              key={field.value}
              select
              label={label}
              required={isRequired}
              tooltip={description}
              error={error?.message && error?.message}
              value={field.value}
              SelectProps={{
                value: field.value,
                onChange: (event) => {
                  field.onChange(event.target.value);
                  if (onChange) {
                    onChange(event.target.value);
                  }
                },
              }}
            >
              {options.map((option) => {
                const isOptionEqualSelectOptionType = isType<SelectOption<string>>(option, 'value');
                const value = isOptionEqualSelectOptionType ? option.value : option;
                const label = isOptionEqualSelectOptionType ? option.label : option;

                return (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                );
              })}
            </Input>
          );
        }}
      />
    );
  }
);
