import React from "react";
import Button from "@/shared/ui/Button";
import { ButtonSizes, ButtonVariants } from "@/shared/ui/Button/types";
import { HistoryManager } from "./HistoryManager";
import { ZoomOutBtn } from "./ZoomOutBtn";

import styles from "./Header.module.scss";

interface HeaderProps {
  save: () => void,
}

export function Header({
  save,
}: HeaderProps) {
  return (
    <div className={styles.header}>
      <HistoryManager />
      <span style={{ flexGrow: 1 }} />
      <Button
        className={styles['save-btn']}
        variant={ButtonVariants.Popup}
        size={ButtonSizes.Medium}
        doesUseInFormOrTable
        onClick={save}
      >
        Сохранить настройки
      </Button>
    </div>
  );
}