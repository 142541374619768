import React, { useEffect, useState } from "react";
import { container } from "tsyringe";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Line,
  LineChart,
  Pie,
  PieChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import dayjs from "dayjs";
import { CounterStore } from "@/entities/Dashboard/widgets/DashboardPlatform/stores/CounterStore";
import { GraphTooltip } from "./GraphTooltip/GraphTooltip";

import styles from "./GraphView.module.scss"


const counterStore = container.resolve(CounterStore);

export interface GraphViewProps {
  data: any[];
  type: string;
  xKeys: string[];
}

export function GraphView(props: GraphViewProps) {
  const { data, type, xKeys } = props
  const [enrichData, setEnrichData] = useState([]);

  const [refArea, setRefArea] = useState({ refAreaLeft: '', refAreaRight: '' });
  let { refAreaLeft, refAreaRight } = refArea;
  const setRefAreaLeft = (val) => setRefArea({ ...refArea, refAreaLeft: val });
  const setRefAreaRight = (val) => setRefArea({ ...refArea, refAreaRight: val });

  useEffect(() => {
    const newData = [];
    for (let i = 0; i < data.length - 1; i++) {
      const current = data[i];
      const next = data[i + 1];
      const gap = next.t - current.t - 1;
      if (gap > 1) {
        if (gap > 2) {
          newData.push(current)
          newData.push({
            t: current.t + 1,
          })
          newData.push({
            t: next.t - 1,
          })
        } else {
          newData.push(current)
          newData.push({
            t: current.t + 1,
          })
        }
      } else {
        newData.push(current)
      }
    }
    setEnrichData(newData)
  }, [data])


  const zoom = () => {

    if (refAreaLeft === refAreaRight || refAreaRight === '') {
      setRefArea(() => ({
        refAreaLeft: '',
        refAreaRight: '',
      }));
      return;
    }

    // xAxis domain
    if (refAreaLeft > refAreaRight) [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

    setRefArea({
      refAreaLeft: '',
      refAreaRight: '',
    });
    if (typeof refAreaLeft === 'number' && typeof refAreaRight === 'number') {
      counterStore.mode = 'history';
      counterStore.absolutePeriod = [dayjs(refAreaLeft * 1000), dayjs(refAreaRight * 1000)];
    }
  }

  if (enrichData.length === 0) {
    return (
      <div className={styles.notData}>
        <div>
          Нет данных
        </div>
      </div>
    )
  }

  switch (type) {
    case "pie":
      return (
        <PieChart>
          <Pie
            data={data}
            dataKey="v"
            nameKey="t"
            cx="50%"
            cy="50%"
            outerRadius={100}
            fill="#82ca9d"
            isAnimationActive={false}
          />
        </PieChart>
      );
    case "line":
      return (
        <ResponsiveContainer className={styles.lineChart}>
          <LineChart
            data={enrichData}
            margin={{ left: 10, right: 10 }}
            onMouseDown={({ activeLabel }) => {
              setRefAreaLeft(activeLabel);
            }}
            onMouseMove={({ activeLabel }) => refAreaLeft && setRefAreaRight(activeLabel)}
            onMouseUp={zoom}
          >
            <CartesianGrid
              strokeDasharray="3 3"
              strokeOpacity={0.1}
            />
            <XAxis
              dataKey="t"
              name="Time"
              tickFormatter={unixTime => dayjs(unixTime * 1000).format("DD.MM.YYYY HH:mm:ss")}
              type="number"
              domain={["auto", "auto"]}
            />
            <YAxis
              yAxisId="1"
            />
            {xKeys && xKeys.map(key => (
              <Line
                key={key}
                type="basis"
                dataKey={key}
                stroke="var(--light-primary-color)"
                dot={false}
                isAnimationActive={false}
                yAxisId="1"
              />
            ))}

            {refAreaLeft && refAreaRight ? (
              <ReferenceArea
                yAxisId="1"
                x1={refAreaLeft}
                x2={refAreaRight}
                strokeOpacity={0.3}
                opacity={0.5}
              />
            ) : null}
            <Tooltip content={<GraphTooltip />} />
          </LineChart>
        </ResponsiveContainer>
      );
    case"bar":
      return (
        <BarChart
          data={data}
          margin={
            {
              left: 10, right: 10,
            }
          }
        >
          <CartesianGrid
            strokeDasharray="3 3"
            strokeOpacity={0.1}
          />
          <XAxis
            dataKey="t"
          />
          <YAxis
            dataKey="v"
            mirror
          />
          <Bar
            dataKey="v"
            fill="#82ca9d"
            isAnimationActive={false}
          />
        </BarChart>
      );
    case"area":
      return (
        <AreaChart
          data={data}
          margin={
            {
              left: 10, right: 10,
            }
          }
        >
          <CartesianGrid
            strokeDasharray="3 3"
            strokeOpacity={0.1}
          />
          <XAxis
            dataKey="t"
          />
          <YAxis
            dataKey="v"
            mirror
          />
          <Area
            dataKey="t"
            fill="#82ca9d"
            stroke="#82ca9d"
            isAnimationActive={false}
          />
        </AreaChart>
      );
    default:
      return null;
  }
}
