import { useEffect, useState } from "react";
import { Dropdown, MenuProps } from 'antd';
import { SyncOutlined, UpOutlined, DownOutlined } from "@ant-design/icons";
import { container } from "tsyringe";
import { get as lodashGet, values } from 'lodash';

import Tooltip from "@/shared/ui/Tooltip";
import { useSearchParamsTemplate } from "@/hooks/useTemplateSearchParams";
import { CounterService } from "@/entities/Dashboard/widgets/DashboardPlatform/services/CounterService";
import { DashboardService } from "@/entities/Dashboard/services/DashboardService";
import { dashboardTitles } from "@/entities/Dashboard/widgets/DashboardPlatform/constants/titles";
import { refreshOptions } from "./constants";

import styles from "./Header.module.scss";


const counterService = container.resolve(CounterService);
const dashboardService = container.resolve(DashboardService);

const getLabel = (key: string | null) => {
  if (key === null) return 'откл'
  const item = refreshOptions.find((itm) => itm.key === key);
  return item?.label;
}

export function Refresh() {
  const { get, set, remove } = useSearchParamsTemplate();
  const refresh = get('refresh');

  const [open, setOpen] = useState(false);

  const handleChangeTimeout: MenuProps['onClick'] = ({ key }) => {
    if (key === 'off') remove(['refresh']);
    else set({ refresh: key });
  };

  const handleRefreshData = () => {
    const dataPanels = lodashGet(dashboardService.selectedDashboard, 'data.panels', {});
    const blockIds = values(dataPanels).map((panel) => lodashGet(panel, 'data.blockIdList', [])).flat();
    counterService.getCounterTimeLinesByBlockIds(blockIds);
  }

  useEffect(() => {
    if (!refresh || !getLabel(refresh)) {
      set({ refresh: '5s' });
    }
    return () => {
      remove(['refresh']);
    }
  }, []);

  const StateIcon = open ? UpOutlined : DownOutlined;
  const icon = (
    <>
      {getLabel(refresh)}
      &nbsp;
      <StateIcon />
    </>
  );

  return (
    <Tooltip title={`Обновить ${dashboardTitles.base}`} placement="left" disableInteractive>
      <span>
        <Dropdown.Button
          className={styles['refresh-btn']}
          icon={icon}
          menu={{
            items: refreshOptions,
            activeKey: refresh || 'off',
            onClick: handleChangeTimeout,
          }}
          onOpenChange={setOpen}
          onClick={handleRefreshData}
        >
          <SyncOutlined style={{ fontSize: '1.3em' }} />
        </Dropdown.Button>
      </span>
    </Tooltip>
  );
}
