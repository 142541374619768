import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'

const UploadFileJSON = (props) => {
    const {addFile, children, ...other} = props
    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                try {
                    let resultParse = JSON.parse(reader.result)
                    addFile && addFile(resultParse)
                } catch (e) {}
            }
            reader.readAsText(file)
        })
    }, [])
    const {getRootProps, getInputProps} = useDropzone({onDrop})

    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} />
            {children}
        </div>
    )
};

export default UploadFileJSON;