import { FC } from 'react';

import { Handle, HandleType, Position } from 'reactflow';
import cn from 'classnames';
import { RelationPositions } from '@/entities/Block/types';
import { observer } from 'mobx-react-lite';

import styles from './HandleListBetweenBlocks.module.scss';

export interface HandleListBetweenBlocksProps {
  connectIds: string[];
  relationPositions: RelationPositions;
  blockId: string;
  handlesType: HandleType;
  lineIdWhichHover: string | undefined;
  lineIdManyToOne?: string | undefined;
}

/**
 * Компонент генерирует точки для линий по 4 на сторону для каждого коннектора
 */
export const HandleListBetweenBlocks: FC<HandleListBetweenBlocksProps> = observer(
  ({ connectIds, relationPositions, blockId, handlesType, lineIdWhichHover }) => {
    return (
      <>
        {connectIds.map((id, index, array) => {
          const area = 100 / array.length;
          const areaCenter = area / 2;

          const gap = handlesType === 'target' ? area * index + areaCenter : 50;

          const relationPositionKeys = Object.keys(relationPositions || []);

          const relationPositionKey = relationPositionKeys.find((key) => {
            return key.includes(blockId) && key.includes(id);
          });

          // const position = relationPositionKey ? relationPositions[relationPositionKey] : undefined;
          const position = undefined;

          return (
            <ul key={id}>
              <li>
                <Handle
                  id={id}
                  type={handlesType}
                  position={Position.Top}
                  style={{ left: `${gap}%` }}
                  className={cn(styles.root, {
                    [styles.hidden]: !(position === Position.Top) || handlesType === 'target',
                    [styles.hover]: lineIdWhichHover === relationPositionKey,
                  })}
                />
              </li>
              <li>
                <Handle
                  id={id}
                  type={handlesType}
                  position={Position.Right}
                  style={{ top: `${gap}%` }}
                  className={cn(styles.root, {
                    [styles.hidden]: !(position === Position.Right) || handlesType === 'target',
                    [styles.hover]: lineIdWhichHover === relationPositionKey,
                  })}
                />
              </li>
              <li>
                <Handle
                  id={id}
                  type={handlesType}
                  position={Position.Bottom}
                  style={{ left: `${gap}%` }}
                  className={cn(styles.root, {
                    [styles.hidden]: !(position === Position.Bottom) || handlesType === 'target',
                    [styles.hover]: lineIdWhichHover === relationPositionKey,
                  })}
                />
              </li>
              <li>
                <Handle
                  id={id}
                  type={handlesType}
                  position={Position.Left}
                  style={{ top: `${gap}%` }}
                  className={cn(styles.root, {
                    [styles.hidden]: !(position === Position.Left) || handlesType === 'target',
                    [styles.hover]: lineIdWhichHover === relationPositionKey,
                  })}
                />
              </li>
            </ul>
          );
        })}
      </>
    );
  }
);
