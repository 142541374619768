import { FC, useEffect, useState } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { Box, Stack } from '@mui/system';
import { useNavigate, useParams } from 'react-router-dom';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import { FieldInput } from '@/shared/ui/Fields/components/FieldInput/FieldInput';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { FlowService } from '@/entities/Flow/services/FlowService';
import { Flow } from '@/entities/Flow/types';
import Button from '@/shared/ui/Button';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { JoyRideService } from '@/entities/JoyRideOnboarding/api/JoyRideService';
import { yupResolver } from '@hookform/resolvers/yup';
import { nameAndDescriptionFieldsValidation } from '@/shared/lib';
import { RoutePaths } from '@/shared/lib/types';
import { DublicateFlowService } from '@/features/DublicateFlow/api/DublicateFlowDialog';

const flowService = container.resolve(FlowService);
const joyrideService = container.resolve(JoyRideService);
const dublicateFlowService = container.resolve(DublicateFlowService);

export const FlowForm: FC = observer(() => {
  const navigate = useNavigate();
  const { has } = useSearchParamsTemplate();
  const { flowId } = useParams();
  const { remove } = useSearchParamsTemplate();

  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    resolver: yupResolver(nameAndDescriptionFieldsValidation),
    values: has(SearchParams.ShowEditFlowForm)
      ? flowService.flows.entities[flowId || '']
      : undefined,
    defaultValues: {
      name: '',
      description: '',
    },
    mode: 'onChange',
  });

  const buttonText = flowService.flows.entities[flowId ?? ''] ? 'Сохранить' : 'Создать';
  const onSubmit: SubmitHandler<Flow> = async (data) => {
    if (joyrideService.run) {
      joyrideService.updateCurrentStep(joyrideService.stepIndex + 1);
    }
    if (has(SearchParams.ShowEditFlowForm) && flowId) {
      await flowService.updateFlow(flowId, data.name, data.description);

      remove([SearchParams.FlowId, SearchParams.ShowEditFlowForm]);
    } else {
      const isNameDuplicate = Object.values(flowService.flows.entities).some(existingFlow => existingFlow.name === data.name);
      setIsDuplicate(isNameDuplicate);

      if (isNameDuplicate) {
        dublicateFlowService.showDialog = true;
        dublicateFlowService.dataInfo = data;
        return;
      }

      const flow = await flowService.createFlow(data);

      navigate(`/${RoutePaths.Flow}/${flow?.id}`);
    }
  };

  useEffect(() => {
    if (!flowId) {
      reset({ name: '', description: '' });
    }
  }, [flowId, reset]);

  return (
    <div>
      <Stack
        component='form'
        className='p-5'
        onSubmit={handleSubmit(onSubmit)}
        justifyContent='space-between'
      >
        <div>
          <Stack spacing={2.5}>
            <FieldInput
              label='Наименование потока'
              name='name'
              register={register}
              error={errors.name}
              description='Введите наименование потока, длинной не более 100 символов'
              id='nameFlow'
            />
            <FieldInput
              textarea
              label='Описание потока'
              name='description'
              register={register}
              error={errors.description}
              description='Введите описание потока, длинной не более 1000 символов'
              id='descriptionFlow'
            />
          </Stack>
        </div>
        <Box>
          <Button
            disabled={!isValid}
            loading={flowService.isLoadingUpdateFlow}
            type='submit'
            variant={ButtonVariants.Primary}
            onClick={handleSubmit(onSubmit)}
            id='createFlow'
          >
            {buttonText}
          </Button>
        </Box>
      </Stack>
    </div>
  );
});

FlowForm.displayName = 'FlowForm';
