export var Mode;
(function (Mode) {
    Mode["AND"] = "AND";
    Mode["RANGE"] = "RANGE";
    Mode["INCREMENT"] = "INCREMENT";
    Mode["NTH_WEEKDAY_OF_MONTH"] = "NTH_WEEKDAY_OF_MONTH";
    Mode["EVERY"] = "EVERY";
    Mode["LAST_DAY"] = "LAST_DAY";
    Mode["LAST_DAY_WEEK"] = "LAST_DAY_WEEK";
    Mode["LAST_NTH_DAY_WEEK"] = "LAST_NTH_DAY_WEEK";
    Mode["DAYS_BEFORE_END_MONTH"] = "DAYS_BEFORE_END_MONTH";
    Mode["NEAREST_WEEKDAY_OF_MONTH"] = "NEAREST_WEEKDAY_OF_MONTH";
    Mode["NONE"] = "NONE";
})(Mode || (Mode = {}));
