import { singleton } from 'tsyringe';
import { makeAutoObservable } from 'mobx';
import { Normalized } from '@/shared/lib/normalize';
import { Dayjs } from 'dayjs';

import { CounterTimeline } from '../components/DashboardPanel/CounterPanel/types';

@singleton()
export class CounterStore {
  counterTimelines: Normalized<CounterTimeline>;

  mode: 'live' | 'history';

  connectStatus: 'on' | 'wait' | 'off';

  timeoutRefresh: number;

  absolutePeriod: [Dayjs, Dayjs];

  relativePeriod: [string, string];

  relativeDisplay: string;

  constructor() {
    this.counterTimelines = { ids: [], entities: {} };
    this.mode = 'live';
    this.connectStatus = 'wait';
    this.timeoutRefresh = 5; // seconds
    this.absolutePeriod = null;
    this.relativePeriod = ['now-1h', 'now'];
    this.relativeDisplay = 'Последний 1 час'

    makeAutoObservable(this);
  }

}
