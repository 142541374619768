import { FC } from 'react';

import { Stack } from '@mui/system';
import Skeleton from '@mui/material/Skeleton';
import cn from 'classnames';
import { Indicator } from '@/shared/ui/Indicator/Indicator';
import { IndicatorTypes } from '@/shared/ui/Indicator/types';
import Tooltip from '@/shared/ui/Tooltip';

import styles from './CardWithActions.module.scss';

export interface CardWithActionsProps {
  title: string | JSX.Element;
  children?: JSX.Element | JSX.Element[];
  actions?: JSX.Element;
  active?: boolean;
  loading?: boolean;
  showIndicator?: boolean;
  indicatorType?: IndicatorTypes;
  indicatorTooltip?: string;
  onClick?: () => void;
  id?: string;
}

export const CardWithActions: FC<CardWithActionsProps> = ({
  title,
  children,
  actions,
  active,
  loading,
  showIndicator,
  indicatorType,
  indicatorTooltip,
  onClick,
    id
}) => {
  const getActions = () => {
    if (active) {
      if (loading) {
        return (
          <Stack direction='row' justifyContent='space-between'>
            <Skeleton variant='circular' width={32} height={32} />
            <Skeleton variant='circular' width={32} height={32} />
            <Skeleton variant='circular' width={32} height={32} />
          </Stack>
        );
      }

      return actions;
    }
  };

  return (
    <div
      tabIndex={0}
      role='button'
      className={cn(styles.root, { [styles.active]: active })}
      onClick={onClick}
      id={id}
    >
      <Stack spacing={1.5}>
        <Stack spacing={0.5}>
          {loading ? (
            <>
              <Skeleton animation='wave' />
              <Skeleton animation='wave' width='80%' />
            </>
          ) : (
            <>
              <div className={styles.header}>
                {typeof title === 'string' ? <p className={styles.title}>{title}</p> : title}
                {showIndicator && (
                  <Tooltip title={indicatorTooltip} placement='right'>
                    <span>
                      <Indicator type={indicatorType!} />
                    </span>
                  </Tooltip>
                )}
              </div>
              {children}
            </>
          )}
        </Stack>
        {actions && getActions()}
      </Stack>
    </div>
  );
};
