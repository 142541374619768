import { CSSProperties, FC, memo } from 'react';

import { Stack } from '@mui/system';
import { ButtonVariants } from '@/shared/ui/Button/types';
import Button from '@/shared/ui/Button';
import { PlusIcon } from '@/shared/ui/Icons/PlusIcon/PlusIcon';
import cn from 'classnames';

import styles from './Sidebar.module.scss';

export interface SidebarProps {
  title: string;
  textAddButton?: string;
  addonBefore?: JSX.Element;
  addonAfter?: JSX.Element;
  addonAfterTitle?: JSX.Element;
  createButton?: JSX.Element;
  onAdd?: () => void;
  showCreateButton?: boolean;
  className?: string;
  id?: string;
  width?: CSSProperties['width'];
}

export const CreateButton: FC<{
  onClick?: (() => void) | undefined;
  text: string;
}> = ({
  onClick = () => {},
  text,
}) => {
  return (
    <Button variant={ButtonVariants.Popup} onClick={onClick} id='addFlow'>
      <Stack direction='row' spacing={1} alignItems='center'>
        <PlusIcon />
        <span>{text}</span>
      </Stack>
    </Button>
  );
}

export const Sidebar: FC<SidebarProps> = memo(
  ({
    title,
    textAddButton = 'Добавить',
    onAdd,
    addonBefore,
    addonAfter,
    showCreateButton = true,
    addonAfterTitle,
    className,
    id,
    width = 256,
    createButton,
  }) => {
    return (
      <div
        id={id}
        className={cn(styles.root, className)}
        style={{ width }}
      >
        <Stack spacing={2}>
          <Stack
            spacing={2.5}
            marginX={2.5}
            marginTop={2.5}
            style={{ overflow: 'hidden' }}
          >
            {showCreateButton &&
              (createButton || (
                <CreateButton onClick={onAdd} text={textAddButton} />
              ))}
            <Stack spacing={2.5}>
              <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <span className={styles.title}>{title}</span>
                {addonAfterTitle}
              </Stack>
              {addonBefore}
            </Stack>
          </Stack>
          <Stack alignItems='center'>{addonAfter}</Stack>
        </Stack>
      </div>
    );
  }
);
