import { container } from 'tsyringe';
import { BlockService } from '@/entities/Block/services/BlockService';
import { BlockStore } from '@/entities/Block/stores/BlockStore';
import { FlowCanvasService } from '@/entities/Flow/services/FlowCanvasService';
import { isEmpty } from 'lodash';

/** Функция вставки блока с использованием горячих клавиш */
export const pasteBlockEventHandler = async (flowId: string | undefined, blockId: string) => {
  if (flowId) {
    const blockService = container.resolve(BlockService);
    const blockStore = container.resolve(BlockStore);
    const flowCanvasService = container.resolve(FlowCanvasService);
    const clipBlock = await navigator.clipboard.readText();
    const { block } = JSON.parse(clipBlock);
    const canvas = flowCanvasService.elements?.entities[blockId];
    await blockService.copyBlock(block, flowId);
    const newBlock = blockStore.copyBlockNew;
    if (!isEmpty(newBlock) && !isEmpty(canvas)) {
      blockService.createBlock({
        ...newBlock,
        id: blockId,
      });
      flowCanvasService.updateElement(
        blockId,
        canvas?.top,
        canvas?.left,
        canvas.sx.height ?? undefined,
        canvas.sx.width ?? undefined
      );
    }
  }
};
