import { observer } from "mobx-react-lite";
import { container } from "tsyringe";
import { ZoomOutOutlined } from '@ant-design/icons';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import Button from '@/shared/ui/Button';
import Tooltip from '@/shared/ui/Tooltip';
import { CounterStore } from "@/entities/Dashboard/widgets/DashboardPlatform/stores/CounterStore";
import { CounterService } from "@/entities/Dashboard/widgets/DashboardPlatform/services/CounterService";


const counterStore = container.resolve(CounterStore);
const counterService = container.resolve(CounterService);


export const ZoomOutBtn = observer(() => {

  const handleClick = () => {
    if (counterStore.mode !== 'history') {
      counterStore.mode = 'history';
      counterService.syncAbsolutePeriod();
    }
    const [start, end] = counterService.unixPeriod;
    const halfDuration = (end - start) / 2;
    const unixPeriod: [number, number] = [start - halfDuration, end + halfDuration];
    counterStore.absolutePeriod = counterService.unixToDayjs(unixPeriod);
  };

  return (
    <Tooltip title="Расширить период" placement="left" disableInteractive>
      <span>
        <Button
          variant={ButtonVariants.Secondary}
          size={ButtonSizes.Medium}
          doesUseInFormOrTable
          onClick={handleClick}
        >
          <ZoomOutOutlined style={{ fontSize: '1.7em', color: 'var(--label-text-color)' }} />
        </Button>
      </span>
    </Tooltip>
  );
});
