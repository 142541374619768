export var Month;
(function (Month) {
    Month["January"] = "January";
    Month["February"] = "February";
    Month["March"] = "March";
    Month["April"] = "April";
    Month["May"] = "May";
    Month["June"] = "June";
    Month["July"] = "July";
    Month["August"] = "August";
    Month["September"] = "September";
    Month["October"] = "October";
    Month["November"] = "November";
    Month["December"] = "December";
})(Month || (Month = {}));
