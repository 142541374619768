export class BaseContainer {
    constructor(d) {
        this.selected = d.selected;
        this.values = this.createValues(d.values);
    }
    getSelected() {
        return this.values.get(this.selected);
    }
    select(mode) {
        const value = this.values.get(mode);
        if (!value) {
            return null;
        }
        return this.cloneWith(Object.assign(Object.assign({}, this), { selected: value.getMode() }));
    }
    setValues(values) {
        return this.cloneWith(Object.assign(Object.assign({}, this), { values: this.createValues(Object.assign(Object.assign({}, this.values), values)) }));
    }
    clone() {
        return this.cloneWith(this);
    }
}
