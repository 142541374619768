import { injectable } from 'tsyringe';
import { makeAutoObservable, runInAction } from 'mobx';
import { uniq } from 'lodash';
import { AxiosResponse } from 'axios';
import { normalize } from '@/shared/lib/normalize';
import { eventEmitter } from '@/shared/api/EventEmitter/EventEmitter';
import { CustomEvents } from '@/shared/api/EventEmitter/types';
import { notify } from '@/shared/ui/Toast/notify';
import { ApiService } from '@/shared/api/Api/services/ApiService';

import { FlowStore } from '../stores/FlowStore';
import {Flow, FlowRequest, FlowResponse, RequestFilter} from '../types';
import { GFlowService } from './GFlowService';
import {Exchange} from "@/entities/Block/types";

@injectable()
export class FlowService {
  constructor(
    private apiService: ApiService,
    private flowStore: FlowStore,
    private gFlowService: GFlowService,
  ) {
    makeAutoObservable(this);
  }

  get flows(): FlowStore['flows'] {
    return this.flowStore.flows;
  }

  get totalPages() {
    return this.flowStore.totalPages;
  }

  get filter() {
    return this.flowStore.filter;
  }

  get isLoadingFlows() {
    return this.flowStore.isLoadingFlows;
  }

  get isLoadingFlow() {
    return this.flowStore.isLoadingFlow;
  }

  get isLoadingUpdateFlow() {
    return this.flowStore.isLoadingUpdateFlow;
  }

  get isLoadingDeleteFlow() {
    return this.flowStore.isLoadingDeleteFlow;
  }

  get sorting() {
    return this.flowStore.sorting;
  }

  set selectedFlowId(id: string) {
    this.flowStore.selectedFlowId = id;
    this.gFlowService.selectedFlowId = id;
  }

  resetFlows() {
    runInAction(() => {
      this.flowStore.flows = { ids: [], entities: {} };
      this.flowStore.totalPages = 0;
    });
  }

  setFilter(nameOrDescription?: string, lastUpdateFrom?: number, lastUpdateTo?: number) {
    let filterList: RequestFilter[] = [];
    const lastUpdateFromString = String(lastUpdateFrom);
    const lastUpdateToString = String(lastUpdateTo);

    if (nameOrDescription) {
      filterList = [
        ...filterList,
        {
          typeCriteria: 'or',
          filterList: [
            {
              typeCriteria: null,
              name: 'name',
              operator: 'regex',
              value: nameOrDescription,
            },
            {
              typeCriteria: null,
              name: 'description',
              operator: 'regex',
              value: nameOrDescription,
            },
          ],
        },
      ];
    }

    if (lastUpdateFrom && lastUpdateTo) {
      filterList = [
        ...filterList,
        {
          typeCriteria: 'and',
          filterList: [
            {
              typeCriteria: null,
              name: 'lastUpdate',
              operator: 'date_gte',
              value: lastUpdateFromString,
            },
            {
              typeCriteria: null,
              name: 'lastUpdate',
              operator: 'date_lte',
              value: lastUpdateToString,
            },
          ],
        },
      ];
    } else {
      if (lastUpdateFrom) {
        filterList = [
          ...filterList,
          {
            typeCriteria: 'and',
            filterList: [
              {
                typeCriteria: null,
                name: 'lastUpdate',
                operator: 'date_gte',
                value: lastUpdateFromString,
              },
            ],
          },
        ];
      }

      if (lastUpdateTo) {
        filterList = [
          ...filterList,
          {
            typeCriteria: 'and',
            filterList: [
              {
                typeCriteria: null,
                name: 'lastUpdate',
                operator: 'date_lte',
                value: lastUpdateToString,
              },
            ],
          },
        ];
      }
    }

    if (filterList.length) {
      this.flowStore.filter = [{ typeCriteria: 'and', filterList }];
      this.resetFlows();
    } else {
      this.flowStore.filter = [];
    }
  }

  resetFilter() {
    this.flowStore.filter = [];
  }

  setSorting(name: keyof Flow, direction: 'asc' | 'desc') {
    this.flowStore.sorting = { name, direction };
  }

  resetSorting() {
    this.flowStore.sorting = null;
  }

  setIsLoadingFlow(value: boolean) {
    this.flowStore.isLoadingFlow = value;
  }

  setFlow(flow: Flow, updateFlowStore? : boolean) {
    if (updateFlowStore) {
      this.flowStore.flows.entities[flow.id] = flow;
    }
    eventEmitter.emit(CustomEvents.InitializeFlow, flow);
  }

  async getFlows(page: number): Promise<void> {
    this.flowStore.isLoadingFlows = true;

    try {
      const response = await this.apiService.instance.post<
        FlowResponse,
        AxiosResponse<FlowResponse>,
        FlowRequest
      >('editor/flow/search', {
        pagination: {
          page,
          size: 20,
        },
        filterList: this.flowStore.filter,
        sortList: this.flowStore.sorting ? [this.flowStore.sorting] : undefined,
      });

      const normalizedFlows = normalize(response.data.flowPage.content, 'id');

      runInAction(() => {
        this.flowStore.totalPages = response.data.flowPage.totalPages;
        this.flowStore.totalElements = response.data.flowPage.totalElements;
        this.flowStore.flows.ids = uniq([...this.flowStore.flows.ids, ...normalizedFlows.ids]);
        this.flowStore.flows.entities = {
          ...this.flowStore.flows.entities,
          ...normalizedFlows.entities,
        };
      });
    } catch (error) {
      notify.error('Не удалось получить потоки');
      throw error;
    } finally {
      this.flowStore.isLoadingFlows = false;
    }
  }

  async getAllFlows() {
    const response = await this.apiService.instance.get<Flow[]>('editor/flow/all');

    runInAction(() => {
      this.flowStore.flows = normalize(response.data, 'id');
      this.flowStore.totalElements = response.data.length;
    });

    return response.data;
  }

  async getFlow(flowId: string): Promise<void> {
    this.flowStore.isLoadingFlow = true;

    try {
      const response = await this.apiService.instance.get<Flow>(`editor/flow/${flowId}`);

      this.flowStore.flows.entities[flowId] = response.data;
      eventEmitter.emit(CustomEvents.InitializeFlow, response.data);
    } catch {
      notify.error('Не удалось получить поток');
    } finally {
      this.flowStore.isLoadingFlow = false;
    }
  }

  async createFlow(flow: Flow) {
    this.flowStore.isLoadingUpdateFlow = true;

    try {
      const formattedFlow = {
        ...flow,
        name: flow.name,
      };

      const response = await this.apiService.instance.put<Flow>('/editor/flow', formattedFlow);

      runInAction(() => {
        this.flowStore.flows.ids.unshift(response.data.id);
        this.flowStore.flows.entities[response.data.id] = response.data;
      });

      return response.data;
    } catch {
      notify.error('Не удалось создать поток');
    } finally {
      this.flowStore.isLoadingUpdateFlow = false;
    }
  }

  async deleteFlow(flowId: string): Promise<void> {
    this.flowStore.isLoadingDeleteFlow = true;

    try {
      await this.apiService.instance.delete(`/editor/flow/${flowId}`);

      runInAction(() => {
        this.flowStore.flows.ids.splice(this.flowStore.flows.ids.indexOf(flowId), 1);
        delete this.flowStore.flows.entities[flowId];
      });
    } catch (error) {
      notify.error('Не удалось удалить поток');
      throw error;
    } finally {
      this.flowStore.isLoadingDeleteFlow = false;
    }
  }

  async updateFlow(
    flowId: string,
    name: string,
    description: string,
  ): Promise<void> {
    this.flowStore.isLoadingUpdateFlow = true;

    try {
      const flow = this.flowStore.flows.entities[flowId];

      await this.apiService.instance.put('/editor/flow', {
        ...flow,
        name,
        description,
        blockIdList: flow.blockIdList ?? [],
      });

      runInAction(() => {

        flow.name = name;
        flow.description = description;

        this.flowStore.flows.ids = uniq([flowId, ...this.flowStore.flows.ids]);
      });
    } catch {
      notify.error('Не удалось изменить поток');
    } finally {
      this.flowStore.isLoadingUpdateFlow = false;
    }
  }

  async clearError(flowId: string) {
    try {
      return await this.apiService.instance.delete<Exchange, AxiosResponse<Exchange>>(
        `editor/flow/${flowId}/clearError`
      );
    } catch (error) {
      notify.error('Не удалось очистить ошибки');
      throw error;
    } finally {
      notify.success('Запущена очистка ошибок');
    }
  }

  async clearLog(flowId: string) {
    try {
      return await this.apiService.instance.delete<Exchange, AxiosResponse<Exchange>>(
        `editor/flow/${flowId}/clearLog`
      );
    } catch (error) {
      notify.error('Не удалось очистить логи');
      throw error;
    } finally {
      notify.success('Запущена очистка логов');
    }
  }
}
