import {
  FC,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  WheelEvent,
} from 'react';

import {
  addEdge,
  Background,
  BackgroundVariant,
  ControlButton,
  Controls,
  MarkerType,
  MiniMap,
  Node,
  NodeDragHandler,
  OnConnect,
  ReactFlow,
  useEdgesState,
  useNodesState,
  useReactFlow,
  useStore,
} from 'reactflow';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import { BlockRelationService } from '@/entities/Block/services/BlockRelationService';
import { FloatingEdge } from '@/entities/Block/components/FloatingEdge/FloatingEdge';
import { ConnectionLine } from '@/entities/Block/components/ConnectionLine/ConnectionLine';
import { Block } from '@/entities/Block/Block';
import BlockType from '@/entities/Block/types';
import { BlockCreationButton } from '@/entities/Block/components/BlockCreationButton/BlockCreationButton';
import { BlockService } from '@/entities/Block/services/BlockService';
import { RegistrableValues } from '@/shared/lib/types';
import { ConnectionEdge } from '@/entities/Block/components/ConnectionEdge/ConnectionEdge';
import { ApplyFlowChanges } from '@/entities/Flow/features';
import { NodeTypes } from '@reactflow/core/dist/esm/types';
import { UnsavedFlowChangesStore } from '@/entities/UnsavedChanges';
import { RunOnKeys } from '@/shared/ui/RunOnKeys/RunOnKeys';
import { ObjectId } from 'bson';
import { KEYBOARD_KEYS } from '@/shared/lib/constants';
import { useParams } from 'react-router-dom';
import { BlockStore } from '@/entities/Block/stores/BlockStore';
import { isEmpty } from 'lodash';

import { FlowCanvasService } from '../../services/FlowCanvasService';

import styles from './FlowWorkspace.module.scss';

const proOptions = { hideAttribution: true };
const fitViewOptions = { duration: 1000};
const defaultNodeTypes = { block: Block };
const edgeTypes = {
  floating: FloatingEdge,
  connection: ConnectionEdge,
};

export interface FlowWorkspaceProps {
  nodeTypes?: NodeTypes;
  isFlowVersion?: boolean;
}

const unsavedFlowChangesStore = container.resolve(UnsavedFlowChangesStore);

export const FlowWorkspace: FC<FlowWorkspaceProps> = observer(
  ({ nodeTypes = defaultNodeTypes, isFlowVersion }) => {
    const { flowId } = useParams();
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const zoomLevel = useStore((store) => store.transform[2]);
    const { setViewport, getViewport, zoomIn, zoomOut } = useReactFlow();
    const [reactFlowInstance, setReactFlowInstance] = useState<any>(null);
    const reactFlowWrapper = useRef<any>(null);
    const [pasteBlock, setPasteBlock] = useState<string>('');
    const [mousePosition, setMousePositionState] = useState<any>(null);

    const { flowCanvasService, blockRelationService, blockService, blockStore } = useMemo(() => {
      container.register(RegistrableValues.FlowId, { useValue: flowId || '' });
      const blockStore = container.resolve(BlockStore);
      const blockService = container.resolve(BlockService);

      const flowCanvasService = container.resolve(FlowCanvasService);
      const blockRelationService = container.resolve(BlockRelationService);

      return {
        blockService,
        blockStore,
        flowCanvasService,
        blockRelationService,
      };
    }, [flowId]);

    const setMousePosition = useCallback<MouseEventHandler>(
      (event) => {
        if (!reactFlowInstance || !reactFlowWrapper?.current) {
          return;
        }

        setMousePositionState(
          reactFlowInstance.screenToFlowPosition({
            x: event.clientX,
            y: event.clientY,
          })
        );
      },
      [reactFlowInstance, reactFlowWrapper]
    );

    const onMouseMove = useCallback<MouseEventHandler>(
      (event) => {
        setMousePosition(event);
        if (flowId && pasteBlock) {
          flowCanvasService.updateElement(pasteBlock, mousePosition.y + 10, mousePosition.x + 60);
        }
      },
      [flowId, pasteBlock, flowCanvasService, mousePosition, setMousePosition]
    );

    const onClick = useCallback<MouseEventHandler>(() => {
      setPasteBlock('');
    }, []);

    const initialNodes: Node[] = useMemo(() => {
      return (
        blockService.blocks?.ids.map((id, index) => {
          return {
            id,
            data: { flowId, index },
            position: {
              x: flowCanvasService.elements?.entities[id]?.left || 0,
              y: flowCanvasService.elements?.entities[id]?.top || 0,
            },
            style: {
              opacity: id === pasteBlock ? 0.5 : 1,
              width: flowCanvasService.elements?.entities[id]?.sx.width || 395,
              height: flowCanvasService.elements?.entities[id]?.sx.height || 395,
            },
            width: flowCanvasService.elements?.entities[id]?.sx.width || 395,
            height: flowCanvasService.elements?.entities[id]?.sx.height || 395,
            type: 'block',
          };
        }) || []
      );
    }, [
      blockService.blocks?.ids.length,
      flowCanvasService.elements?.entities,
      flowCanvasService.elements?.entities[pasteBlock]?.top,
      flowCanvasService.elements?.entities[pasteBlock]?.left,
    ]);

    const onDragOver = useCallback(
      (event: any) => {
        event.preventDefault();
        setMousePosition(event);
        event.dataTransfer.dropEffect = 'move';
      },
      [setMousePosition]
    );

    const onDrop = useCallback(
      (event: any) => {
        event.preventDefault();
        const type = event.dataTransfer.getData('application/reactflow');
        if (typeof type === 'undefined' || !type) {
          return;
        }

        if (type === 'block') {
          const block = {
            flowId: flowId || '',
            id: new ObjectId().toString(),
            name: `Блок ${(blockService.blocks?.ids.length || 0) + 1}`,
            logSettings: {
              level: 'debug',
              retentionTime: 5184000000,
            },
            position: mousePosition,
          } as BlockType;

          blockService.createBlock(block);
        }
      },
      [reactFlowInstance, mousePosition]
    );

    const handleDragStop = useCallback<NodeDragHandler>(
      (event, { position, id, width, height }) => {
        if (flowId) {
          flowCanvasService.updateElement(
            id,
            position.y,
            position.x,
            height ?? undefined,
            width ?? undefined
          );
        }
      },
      [flowCanvasService, flowId]
    );

    const onConnect = useCallback<OnConnect>(
      (params) => {
        setEdges((eds) =>
          addEdge({ ...params, type: 'connection', markerEnd: { type: MarkerType.Arrow } }, eds)
        );
        blockService.isVisiblePanelChooseConnects = true;
        blockService.isVisibleConnectionLine = false;
        blockService.nodeIdStartConnect = null
      },
      [blockService, setEdges]
    );

    const handleFlowConnectStart = useCallback((event: any, params: { nodeId: string; }) => {
      blockService.isVisibleConnectionLine = true;
      blockService.nodeIdStartConnect = params.nodeId
    }, [blockService]);

    const handleFlowConnectEnd = useCallback(
      (/*{ target }*/) => {
        // target - html element on mouse up
        blockService.isVisibleConnectionLine = false;
        blockService.nodeIdStartConnect = null
      },
      [blockService]
    );

    const handleWheel = useCallback(
      (event: WheelEvent<HTMLDivElement>) => {
        const viewport = getViewport();

        if (event.ctrlKey || event.metaKey) {
          const up = event.deltaY < 0;
          if (up) {
            zoomIn();
          } else {
            zoomOut();
          }
        } else {
          if (event.shiftKey) {
            setViewport({...viewport, x: viewport.x - event.deltaY / 2.0, y: viewport.y - event.deltaX * 2.0})
          } else {
            setViewport({...viewport, x: viewport.x + event.deltaX * 2.0, y: viewport.y - event.deltaY / 2.0})
          }
        }
      },
      [getViewport, setViewport, zoomIn, zoomOut]
    );

    useEffect(() => {
      setNodes(initialNodes);
    }, [initialNodes, setNodes]);

    useEffect(() => {
      if (blockService.blocks?.ids.length) {
        setEdges(blockRelationService.getEdges());
      }
    }, [blockRelationService.relations?.length, blockService.blocks?.ids.length]);

    useEffect(() => {
      if (!flowId) {
        unsavedFlowChangesStore.areThereChanges = false;
      }
    }, [flowId]);

    useEffect(() => {
      let timeoutId = 0;

      timeoutId = window.setTimeout(() => {
        document.querySelectorAll('.react-flow__controls-button ').forEach((element) => {
          element.removeAttribute('title');
        });

        clearTimeout(timeoutId);
      }, 100);
    }, []);

    const { Control, Escape, V } = KEYBOARD_KEYS;
    const keysEvents = [
      {
        keys: [Control, V],
        event: async () => {
          if (!pasteBlock) {
            const clipBlock = await navigator.clipboard.readText();
            const { block, sx } = JSON.parse(clipBlock);
            if (flowId) await blockService.copyBlock(block, flowId);
            const newBlock = blockStore.copyBlockNew;
            if (!isEmpty(newBlock)) {
              blockService.createBlock({
                ...newBlock,
                position: {
                  x: mousePosition.x + sx.width / 2 + 60,
                  y: mousePosition.y + sx.height / 2 + 10,
                },
              });
              setPasteBlock(newBlock.id);
            }
          }
        },
      },
      {
        keys: [Escape],
        event: () => {
          if (pasteBlock) {
            blockService.deleteBlock(pasteBlock);
            setPasteBlock('');
          }
        },
      },
    ];
    return (
      <RunOnKeys keysEvents={keysEvents} tabIndex={1}>
        <motion.div
          style={{ height: '100%' }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          ref={reactFlowWrapper}
        >
          <ReactFlow
            nodes={nodes}
            nodeTypes={nodeTypes}
            onNodesChange={onNodesChange}
            proOptions={proOptions}
            onNodeDragStop={handleDragStop}
            onConnect={onConnect}
            onConnectStart={handleFlowConnectStart}
            onConnectEnd={handleFlowConnectEnd}
            edges={edges}
            edgeTypes={edgeTypes}
            onEdgesChange={onEdgesChange}
            connectionLineComponent={ConnectionLine}
            fitView
            multiSelectionKeyCode='Shift'
            selectionKeyCode='Shift'
            zoomOnScroll={false}
            zoomOnPinch={false}
            panOnDrag={[1, 2]}
            fitViewOptions={{ maxZoom: 0.75 }}
            selectionOnDrag
            onWheel={handleWheel}
            onInit={setReactFlowInstance}
            onClick={onClick}
            onMouseMove={onMouseMove}
            onDrop={onDrop}
            onDragOver={onDragOver}
          >
            <Background
              className={styles.canvas}
              color='#32333a'
              variant={BackgroundVariant.Lines}
              gap={30}
            />
            <Controls
              className={styles.controls}
              position='top-left'
              fitViewOptions={fitViewOptions}
            >
              <ControlButton className='react-flow__controls-scale'>
                {Math.round(zoomLevel * 100)}%
              </ControlButton>
              <ApplyFlowChanges isFlowVersion={isFlowVersion} />
            </Controls>
            <MiniMap
              zoomable
              pannable
              ariaLabel={null}
              position='bottom-left'
              maskColor='#2a2d36b8'
              nodeColor='var(--paper-color)'
              nodeStrokeColor='var(--primary-color)'
              nodeBorderRadius={16}
            />
          </ReactFlow>
          {flowId && <BlockCreationButton />}
        </motion.div>
      </RunOnKeys>
    );
  }
);

FlowWorkspace.displayName = 'FlowWorkspaceContainer';
