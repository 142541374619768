import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import Stack from '@mui/system/Stack';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { FieldSwitch } from '@/shared/ui/Fields/components/FieldSwitch/FieldSwitch';
import { FieldInput } from '@/shared/ui/Fields/components/FieldInput/FieldInput';
import { RunOnKeys } from '@/shared/ui/RunOnKeys/RunOnKeys';
import {
  CONNECTOR_FORM_SEARCH_PARAMS_TEMPLATE,
  SearchParams,
  useSearchParamsTemplate,
} from '@/hooks/useTemplateSearchParams';
import { yupResolver } from '@hookform/resolvers/yup';
import { nameAndDescriptionFieldsValidation } from '@/shared/lib';
import { RegistrableValues } from '@/shared/lib/types';
import Accordion from '@mui/material/Accordion';
import {
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { KEYBOARD_KEYS } from '@/shared/lib/constants';
import { ObjectId } from 'bson';
import { ConnectService } from '@/entities/Connect/services/ConnectService';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { FlowCanvasService } from '@/entities/Flow/services/FlowCanvasService';
import { pasteBlockEventHandler } from '@/entities/Block/utils/keyboardKeys';
import { BlockStore } from '@/entities/Block/stores/BlockStore';

import Button from '../../../shared/ui/Button';
import { ConnectList } from '../../Connect/components/ConnectList/ConnectList';
import { ConnectSizes, ConnectTypes } from '../../Connect/components/ConnectList/types';
import { BlockService } from '../services/BlockService';
import Block from '../types';
import { AllConnectService } from '../../Connect/services/AllConnectService';

import styles from './BlockForContainer.module.scss';

const allConnectService = container.resolve(AllConnectService);
const blockStore = container.resolve(BlockStore);

export const BlockFormContainer: FC = observer(() => {
  const { flowId } = useParams();
  const { get, remove, set } = useSearchParamsTemplate();
  const [activeButton, setActiveButton] = useState('STANDARD');
  /** Данные  блока для отображения в форме */
  const [pasteBlock, setPasteBlock] = useState<string>('');
  const [isOpenSnackbar, setIsOpenSnackbar] = React.useState<boolean>(false);

  const blockId = get(SearchParams.BlockId);

  const connectService = useMemo(() => {
    container.register(RegistrableValues.BlockId, { useValue: blockId });
    return container.resolve(ConnectService);
  }, [blockId]);

  const { blockService, flowCanvasService } = useMemo(() => {
    container.register(RegistrableValues.FlowId, { useValue: flowId || '' });
    const blockService = container.resolve(BlockService);
    const flowCanvasService = container.resolve(FlowCanvasService);
    return {
      blockService,
      flowCanvasService,
    };
  }, [flowId]);
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm({
    resolver: yupResolver(nameAndDescriptionFieldsValidation),
    values: blockService.blocks?.entities[blockId || ''] || undefined,
    mode: 'onChange',
    shouldFocusError: false,
  });
  const block = blockService.blocks?.entities[blockId];
  const copyBlock = blockStore.copyBlockNew;
  const blockValues = blockService.blocks?.entities[blockId];

  /** Сеттер данных блока */
  useEffect(() => {
    setPasteBlock(JSON.stringify({ block: copyBlock ? copyBlock : block }));
  }, [copyBlock]);

  useEffect(() => {
    !blockId && reset({ name: '', description: '' });
  }, [blockId, reset]);

  const onSubmit: SubmitHandler<Block> = (data) => {
    if (blockId) {
      blockService.updateBlock({ ...data });
      // , isManyToOne: activeButton === 'N_TO_1'
    } else {
      const id = new ObjectId().toString();
      blockService.createBlock({
        ...data,
        flowId: flowId || '',
        flowName: data.flowName,
        id,
      });
    }
  };

  const handleMessageDeliverySettingsButtonClick = useCallback(() => {
    remove([SearchParams.ShowLogSettings, ...CONNECTOR_FORM_SEARCH_PARAMS_TEMPLATE]);
    set([SearchParams.ShowMessageDeliverySettings]);
  }, [remove, set]);

  const handleLogSettingsButtonClick = useCallback(() => {
    remove([SearchParams.ShowMessageDeliverySettings, ...CONNECTOR_FORM_SEARCH_PARAMS_TEMPLATE]);
    set([SearchParams.ShowLogSettings]);
  }, [remove, set]);

  const handleConnectListReorder = useCallback(
    (newOrder: string[]) => {
      connectService.reorderProcessors(newOrder);
    },
    [connectService]
  );

  const handleButtonStandardClick = useCallback(() => {
    if (block) {
      blockService.updateBlock({ ...block, isManyToOne: false });

      setActiveButton('STANDARD');
    }
  }, [block, blockService]);

  const handleButtonNClick = useCallback(
    (e) => {
      if (block) {
        blockService.updateBlock({ ...block, isManyToOne: true });
        setActiveButton('N_TO_1');
      }
    },
    [block, blockService]
  );

  /** Очистка блока для сброса настроек */
  const cleanPastBlockHandler = () => {
    setPasteBlock('');
  };
  /** Копирование блока */
  const copyBlockEventHandler = async () => {
    if (block) {
      const { sx } = flowCanvasService.elements?.entities[block.id];
      await navigator.clipboard.writeText(JSON.stringify({ block, sx }));
      setIsOpenSnackbar(true);
    }
  };

  const { Control, V } = KEYBOARD_KEYS;

  const keysEvents = [
    {
      keys: [Control, V],
      event: async () => {
        await pasteBlockEventHandler(flowId, blockId);
      },
    },
  ];

  return (
    <RunOnKeys keysEvents={keysEvents} tabIndex={1!}>
      <div className={styles.root}>
        <form onBlur={handleSubmit(onSubmit)} onSubmit={handleSubmit(onSubmit)}>
          <Stack className='p-5' gap={5}>
            <Stack spacing={2}>
              <Stack spacing={2} style={{ display: 'flex', flexDirection: 'row' }}>
                <Button
                  variant={
                    !blockValues?.isManyToOne ? ButtonVariants.Popup : ButtonVariants.Secondary
                  }
                  onClick={handleButtonStandardClick}
                  style={{
                    maxWidth: '193px',
                    borderTopRightRadius: '0',
                    borderBottomRightRadius: '0',
                  }}
                >
                  <span>Стандартный</span>
                </Button>
                <Button
                  variant={
                    blockValues?.isManyToOne ? ButtonVariants.Popup : ButtonVariants.Secondary
                  }
                  onClick={handleButtonNClick}
                  style={{
                    maxWidth: '193px',
                    marginTop: 0,
                    borderTopLeftRadius: '0',
                    borderBottomLeftRadius: '0',
                  }}
                >
                  <span>N к 1</span>
                </Button>
              </Stack>
              <Stack spacing={2}>
                <FieldInput
                  label='Наименование блока'
                  name='name'
                  register={register}
                  error={errors.name}
                  description='Введите наименование блока, длинной не более 100 символов'
                  id='nameBlock'
                />
                <FieldInput
                  textarea
                  label='Описание блока'
                  name='description'
                  register={register}
                  error={errors.description}
                  description='Введите описание блока, длинной не более 1000 символов'
                  id='descriptionBlock'
                />
                <FieldSwitch
                  defaultValue='true'
                  label='Активность'
                  name='isActive'
                  control={control}
                  error={errors.isActive}
                  parameterList={[]}
                />
              </Stack>
              <Stack spacing={2} id='handlerStack'>
                <ConnectList
                  title='Коннекторы входящих данных'
                  connectors={connectService.inputList}
                  connectorCreatorOptions={allConnectService.inputOptions}
                  type={ConnectTypes.Input}
                  size={ConnectSizes.Large}
                  blockId={blockId || ''}
                  id='handlerConnector'
                />
                <ConnectList
                  title='Обработчик данных'
                  connectors={connectService.processorList}
                  connectorCreatorOptions={allConnectService.processorOptions}
                  size={ConnectSizes.Large}
                  type={ConnectTypes.Processor}
                  blockId={blockId || ''}
                  draggable
                  onReorder={handleConnectListReorder}
                  id='handlerInfo'
                />
                <ConnectList
                  title='Коннекторы исходящих данных'
                  connectors={connectService.outputList}
                  connectorCreatorOptions={allConnectService.outputOptions}
                  size={ConnectSizes.Large}
                  type={ConnectTypes.Output}
                  blockId={blockId || ''}
                  id='handlerOutputConnector'
                />
                <Accordion elevation={0} variant='outlined'>
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls='panel1-content'
                    id='panel1-header'
                  >
                    <Typography>Копирование/Вставка блока</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={styles.buttonBlock}>
                      <IconButton aria-label='copy' onClick={copyBlockEventHandler}>
                        <ContentCopyIcon />
                      </IconButton>
                      <IconButton aria-label='delete' onClick={cleanPastBlockHandler}>
                        <DeleteOutlineIcon />
                      </IconButton>
                    </div>
                    <TextField
                      className={styles.copyBlock}
                      name='pasteBlock'
                      multiline
                      rows={6}
                      value={pasteBlock}
                      variant='standard'
                      id='copyBlock'
                    />
                  </AccordionDetails>
                </Accordion>

                <Button
                  doesUseInFormOrTable
                  variant={ButtonVariants.Secondary}
                  onClick={handleMessageDeliverySettingsButtonClick}
                >
                  Настройка доставки сообщений
                </Button>
                <Button
                  doesUseInFormOrTable
                  variant={ButtonVariants.Secondary}
                  onClick={handleLogSettingsButtonClick}
                >
                  Настройка логирования
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </form>
        <Snackbar
          className={styles.snackbars}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={isOpenSnackbar}
          autoHideDuration={1500}
          onClose={() => setIsOpenSnackbar(false)}
          message='Блок скопирован'
        />
      </div>
    </RunOnKeys>
  );
});
