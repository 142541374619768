import React, { useState } from "react";
import { container } from "tsyringe";
import { Popover, Button as AButton } from "antd";
import {
  ClockCircleOutlined,
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { observer } from "mobx-react-lite";
import { Dayjs } from "dayjs";
import NavigateBeforeOutlinedIcon from "@mui/icons-material/NavigateBeforeOutlined";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";

// import Button from "@/shared/ui/Button";
import { ButtonSizes, ButtonVariants } from "@/shared/ui/Button/types";
import { Button } from "@/shared/ui";
import { CounterStore } from "@/entities/Dashboard/widgets/DashboardPlatform/stores/CounterStore";
import { CounterService } from "@/entities/Dashboard/widgets/DashboardPlatform/services/CounterService";
import { PeriodPicker } from "./PeriodPicker";
import { PredefinedPeriods } from "./PredefinedPeriods";
import { Refresh } from "./Refresh";

import styles from './Header.module.scss';
import { ZoomOutBtn } from "@/entities/Dashboard/widgets/DashboardPlatform/components/Header/ZoomOutBtn";

const counterStore = container.resolve(CounterStore);
const counterService = container.resolve(CounterService);

const format = 'DD.MM.YYYY HH:mm';

export const HistoryManager = observer(() => {
  const { mode } = counterStore;
  const historyMode = mode === 'history';
  const [clicked, setClicked] = useState(false);
  // const [hovered, setHovered] = useState(false);

  const hide = () => {
    setClicked(false);
    // setHovered(false);
  };


  // const handleHoverChange = (open: boolean) => {
  //   setHovered(open);
  //   setClicked(false);
  // };

  const handleClickChange = (open: boolean) => {
    // setHovered(false);
    setClicked(open);
  };
  const { absolutePeriod, relativePeriod } = counterStore;
  let value: [Dayjs, Dayjs];
  if (absolutePeriod) {
    const [startPeriod, endPeriod] = counterStore.absolutePeriod;
    value = [startPeriod, endPeriod];
  }

  const handleStep = (forward: boolean) => () => {
    if (counterStore.mode !== 'history') {
      counterStore.mode = 'history';
      counterService.syncAbsolutePeriod();

    }
    const [start, end] = counterService.unixPeriod;
    const duration = end - start;

    const unixPeriod: [number, number] = forward
      ? [start + duration, end + duration]
      : [start - duration, end - duration];
    counterStore.absolutePeriod = counterService.unixToDayjs(unixPeriod);
  };
  const renderTitle = () => {
    if (counterStore.mode === 'live') return counterStore.relativeDisplay;
    const [startPeriod, endPeriod] = counterStore.absolutePeriod;
    return `${startPeriod?.format(format)} по ${endPeriod?.format(format)}`;
  }
  const StateIcon = clicked ? UpOutlined : DownOutlined;

  const popoverContent = (
    <>
      <PredefinedPeriods
        closePopup={hide}
      />
      <PeriodPicker />
    </>
  );

  return (
    <>
      {historyMode && (
        <Button
          variant={ButtonVariants.Secondary}
          size={ButtonSizes.Medium}
          doesUseInFormOrTable
          onClick={handleStep(false)}
        >
          <NavigateBeforeOutlinedIcon />
        </Button>
      )}
      <Popover
        overlayClassName={styles.popover}
        content={popoverContent}
        trigger="click"
        open={clicked}
        arrow={false}
        placement='bottomLeft'
        onOpenChange={handleClickChange}
      >
        <AButton
          className={styles['time-manager-btn']}
          icon={<ClockCircleOutlined style={{ fontSize: '1.3em' }} />}
        >
          {renderTitle()}
          &nbsp;
          <StateIcon />
        </AButton>
      </Popover>
      {historyMode && (
        <Button
          variant={ButtonVariants.Secondary}
          size={ButtonSizes.Medium}
          doesUseInFormOrTable
          onClick={handleStep(true)}
        >
          <NavigateNextOutlinedIcon />
        </Button>
      )}
      <ZoomOutBtn />
      {mode === 'live' && <Refresh />}
    </>
  );
});
